<template>
  <div>

    <!--秒杀-->
    <div v-if="goodsStatus == 2" class="miaosha-box">
      <div class="price">
        <div class="n-price">
          <span v-if="flashInfo.state==1" class="msj">会员价</span>
          <span v-else class="msj">{{ flashInfo.showRemind }}</span>
          <span class="m-icon">¥</span>
          <span v-if="flashInfo.state==1" class="count">
            {{ flashInfo.vipPrice }}
          </span>
          <span v-else class="count">
            {{ flashInfo.goodVipPrice }}
          </span>
          <span class="count_no_boder">官网价¥{{ flashInfo.goodPrice }}</span>
        </div>

        <div class="o-price">
          <p class="priceSupplier">供应商 {{ goodsInfo.goodSupplier }}</p>
          <p v-if="flashInfo.state==1" class="o-price-Notice-box"><span class="Notice">秒杀预告</span>
            {{ flashInfo.startHour }} {{ flashInfo.seckillPrice }}</p>

        </div>

      </div>
      <div class="times">
        <div class="ms-txt" />

        <div v-if="flashInfo.state==2" class="times-box">
          <span class="tim">{{ countDownHour }}</span> :
          <span class="tim">{{ countDownMinute }}</span> :
          <span class="tim">{{ countDownSecond }}</span>
        </div>

        <div v-if="flashInfo.state==1" class="times-text">
          即将开始秒杀

        </div>

      </div>
    </div>

    <!--首页 新人专属-->
    <div class="goods-info">

      <div v-if="goodsStatus != 2" class="price-info">
        <div v-if="goodsStatus !=7" class="price">

          <div class="price-now">
            <!-- 优选 -->
            <img v-if="markerUrl" style="vertical-align: middle; height: 17px; width: auto; margin-right: 10px" class="types" :src="markerUrl">
            <span class="special">{{ goodsInfo.showRemind }} ¥</span>
            <span v-if="goodsInfo.zhiboPrice>0">
              {{ goodsInfo.zhiboPrice }}
            </span>
            <span v-else>
              {{ goodsInfo.showPrice }}
            </span>
          </div>

          <div class="price-old">{{ goodsInfo.priceName }}¥{{ goodsInfo.goodPrice }}</div>
          <div v-if="goodsInfo.goodSupplier" class="goodSupplier"> {{ '供应商 ' + goodsInfo.goodSupplier }}</div>

        </div>

        <div v-else class="price">
          <div class="price-now">
            <span class="special">¥ </span>
            <span>{{ goodsInfo.showPrice }}</span>
          </div>
          <div class="price-old">{{ goodsInfo.priceName }}¥{{ goodsInfo.goodPrice }}</div>
          <!--          拼团人数-->
          <div class="SpellGroupWrapStyle">
            <span class="numberTuan">{{ goodsInfo.needer }}人团</span>
            <span class="immediately" @click="gotoGroup">立即开团</span>
          </div>
        </div>

        <div class="handle">
          <!-- <div class="love">
                        <img
                            class="icon-love"
                            src="../../assets/images/icon-love.png"
                        />
                        <div>收藏</div>
                    </div>
                    <div class="share">
                        <img
                            class="icon-share"
                            src="../../assets/images/icon-share.png"
                        />
                        <div>分享</div>
          </div>-->
        </div>
      </div>
      <!-- <div v-if="skuInfo"> {{skuInfo}}</div> -->

      <div v-if="skuInfo&&skuInfo.labelInfo.isShowIcon===1" class="goodsinfo_tags_box">
        <!--        <span v-for="(icon,index) in skuInfo.labelInfo.labelInfo" :key="index">{{icon.name}}</span>-->
      </div>

      <div
        v-if="goodsInfo.isBigGiftBag===0&&goodsInfo.activityRules&&goodsInfo.activityRules.length!==0"
        class="isBigGiftBag"
      >
        <span v-for="(tag,index) in goodsInfo.activityRules" :key="index">{{ tag }}</span>
      </div>
      <!--渠道图+商品名称-->
      <div class="goods-title">
        <img v-if="goodsInfo.channelIcon" style="vertical-align: middle;" class="types" :src="goodsInfo.channelIcon">
        <span style="vertical-align: middle;">{{ goodsInfo.goodName }}</span>
      </div>
      <p class="p" v-html="$options.filters.fil(goodsInfo.goodsInfo)" />
      <!-- 秒杀不展示悦淘优选 -->
      <!-- <div class="attention" v-if="goodsStatus == 1 || goodsStatus == 2">
        <img class="sd" src="../../assets/images/shan-dian.png" />
        <span>悦淘优选·闪电发货·极速退货·七天无理由退换货1</span>
      </div> -->
    </div>

    <div
      v-if="(goodsStatus == 3 || goodsStatus == 4) && goodsInfo.coupon.discount && goodsInfo.coupon.use_end_time"
      class="cps-box"
      @click="toOutside(goodsInfo.buy_url)"
    >
      <div class="cps-coupon">
        <div class="price">
          <span class="spe">¥</span>
          <span>{{ goodsInfo.coupon.discount }}</span>
        </div>
        <div class="inf">
          <div class="spe">优惠券</div>
          <div>有效期到{{ goodsInfo.coupon.use_end_time }}</div>
        </div>
        <div class="get-coupon">立即领取</div>
      </div>
    </div>

    <MiniCart v-if="cartData" :goods-status="goodsStatus" :datainfo="cartData" @success="addCartSuccess" />

  </div>
</template>

<script>
/* eslint-disable eqeqeq */
import Vue from 'vue'
import { Toast } from 'vant' // 提示框
import {
  skuChangeInfo
} from '@/services/goods.js'

Vue.use(Toast)
import MiniCart from '@/component/GoodsDetail/MiniCart'

export default {
  components: {
    MiniCart
  },
  filters: {
    fil(v) {
      return v.replace(/\s+/g, '<br/>')
    }
  },
  props: {
    goodsInfo: {
      // 商品详情数据
      type: Object,
      default: null
    },
    goodsStatus: {
      // 1:自营详情；2:秒杀详情；3:京东详情；4:拼多多详情；5:淘宝详情；6:唯品会详情
      type: Number,
      default: 1
    },
    flashInfo: {
      // 秒杀信息
      type: Object,
      default: null
    },
    // 拼团信息
    spellGroupInfo: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      cartData: null,
      skuInfo: null,
      countDownHour: '00',
      countDownMinute: '00',
      countDownSecond: '00'
    }
  },
  computed: {
    markerUrl() {
      const { markerUrl } = this.goodsInfo
      // const isShowChannels = [0, 3, 6, 7, 8, 11, 12]
      // return isShowChannels.includes(channelId)
      return markerUrl && markerUrl !== '' ? markerUrl : null
    }
  },
  mounted() {
    this.skuChangeGoodsInfo()
    if (this._props.goodsStatus == 2) {
      const data = this._props.flashInfo
      if (data.state == 1) {
        this.startTimer(data.startTime - data.nowTime)
      } else {
        this.startTimer(data.endTime - data.nowTime)
      }
    }
  },
  created() {
  },
  methods: {
    // 去开团
    gotoGroup() {
      const obj = {
        buyType: 1,
        id: this.$route.query.id
      }

      this.cartData = obj
      this.$store.commit('changeShowSku', true)
    },
    addCartSuccess(data) {
    },
    skuChangeGoodsInfo() {
      const _that = this
      const shareId = localStorage.getItem('shareCodeNumber')
      if (this.$route.query.skuid || this.$route.query.product_sku_id) {
        const data = {
          shareId,
          uid: window.localStorage.getItem('uid') || 0,
          productSkuId: this.$route.query.skuid || this.$route.query.product_sku_id || '',
          productType: 0,
          liveId: this.$route.query.liveid || 0,
          addressCode: this.$store.state.addressInfo.areaIds || 0,
          lon: 0,
          lat: 0
        }
        skuChangeInfo(data).then((res) => {
          if (Number(res.code) === 200) {
            _that.$nextTick(() => {
              _that.skuInfo = res.data
            })
          }
        })
      }
    },
    startTimer(totalSecond) {
      // 倒计时
      // eslint-disable-next-line no-redeclare
      var totalSecond = totalSecond
      var interval = setInterval(
        function() {
          // 秒数
          var second = totalSecond
          // 小时位
          var hr = Math.floor(second / 3600)
          var hrStr = hr.toString()
          if (hrStr.length == 1) hrStr = '0' + hrStr
          // 分钟位
          var min = Math.floor((second - hr * 3600) / 60)
          var minStr = min.toString()
          if (minStr.length == 1) minStr = '0' + minStr
          // 秒位
          var sec = second - hr * 3600 - min * 60
          var secStr = sec.toString()
          if (secStr.length == 1) secStr = '0' + secStr
          this.countDownHour = hrStr
          this.countDownMinute = minStr
          this.countDownSecond = secStr
          totalSecond--
          if (totalSecond < 0) {
            Toast('秒杀商品已抢光')
            clearInterval(interval)
            setTimeout(() => {
              window.history.go(-1)
            }, 1000)
            this.countDownHour = '00'
            this.countDownMinute = '00'
            this.countDownSecond = '00'
          }
        }.bind(this),
        1000
      )
    },
    toOutside(url) {
      // 点击去购买
      if (window.localStorage.getItem('uid')) {
        window.location.href = url
      } else {
        this.$store.dispatch('loginIntercept', this.$route.query)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.count_no_boder {
  text-decoration: line-through;
  color: #e69b9b;
}

.miaosha-box {
  width: 100%;
  // height: 75px;
  background: #ff001c;
  display: flex;
  justify-content: space-between;

  .price {
    width: 234px;
    // height: 75px;
    background: #ff001c;
    box-sizing: border-box;
    padding-left: 18px;
    padding-top: 1px;
    color: #fff;

    .n-price {
      height: 30px;
      display: flex;
      align-items: center;

      .msj {
        font-size: 13px;
        margin-right: 11px;
      }

      .m-icon {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: -2px;
      }

      .count {
        // font-weight: bold;
        font-size: 26px;
        margin-right: 4px;
      }
    }

    .o-price {
      font-size: 10px;

      text-align: left;
      margin-top: -6px;

      p {
        margin: 7px 0;
      }

      .Notice {
        background: #f7d77e;
        padding: 4px 4px;
        border-radius: 10px;
        color: #000;
      }

      .priceSupplier {
        font-size: 10px;
        color: #e69b9b;

        //  transform: scale(0.9);

      }

      .o-price-Notice-box {

        color: #f7d77e;
      }
    }
  }

  .times {
    width: 141px;
    height: 70px;
    //background-image: url("../../assets/images/miaosha-bg.png");
    background-image: url("../../assets/images/ms2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;

    .ms-txt {
      width: 81px;
      height: 22px;
      //background: url("../../assets/images/xsms.png") no-repeat;
      background-image: url("../../assets/images/ms1.png");
      background-size: 100% 100%;
      position: absolute;
      right: 14px;
      top: 8px;
    }

    .times-box {
      position: absolute;
      right: 14px;
      bottom: 5px;
      color: #fff;
      font-weight: bold;

      .tim {
        display: inline-block;
        width: 22px;
        height: 22px;
        font-size: 13px;
        color: #000;
        border-radius: 4px;
        //background: #201e1d;
        background: #dac88b;
        line-height: 22px;
      }
    }

    .times-text {

      position: absolute;
      right: 14px;
      bottom: 15px;
      color: #f3b937;

    }
  }
}

.goods-info {
  width: 100%;
  padding: 9px 12px;
  background: #fff;

  .price-info {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .price {
      display: flex;
      align-items: center;

      .price-now {
        display: inline-block;
        color: #ff001c;
        font-size: 21px;
        margin-right: 11px;

        .special {
          font-size: 14px;
        }
      }

      .price-old {
        display: inline-block;
        color: #999999;
        font-size: 12px;
        text-decoration: line-through;

      }

      .goodSupplier {
        font-size: 12px;
        display: inline-block;
        margin-left: 5px;
        color: #999999;
      }
    }

    .handle {
      font-size: 10px;
      color: #999;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .love {
        .icon-love {
          width: 15px;
          height: 14px;
          margin-bottom: 2.5px;
        }

        // margin-right: 22px;
      }

      .share {
        .icon-share {
          width: 13px;
          height: 13px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .goods-title {
    font-size: 14px;
    color: #141414;
    text-align: justify;
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .types {
      width: 16px;
      margin-right: 5px;
    }
  }

  .p {
    width: 100%;
    font-size: 11px;
    color: #999999;
    text-align: left;
    margin-bottom: 5px;
    line-height: 17px;
  }

  .attention {
    width: 100%;
    height: 30px;
    background: #ffe9e9;
    border-radius: 5px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 11px;
    font-size: 13px;
    color: #ff001b;
    margin-top: 13px;

    .sd {
      width: 20px;
      height: 20px;
    }
  }
}

.cps-box {
  width: 100%;
  height: 106px;
  background: #f5f7fa;
  box-sizing: border-box;
  padding: 8px 14px;

  .cps-coupon {
    width: 100%;
    height: 78px;
    background: url("../../assets/images/coupon-bg.png") no-repeat;
    background-size: 100% 100%;
    position: relative;

    .price {
      font-size: 26px;
      color: #ed2e2e;
      position: absolute;
      left: 24px;
      top: 50%;
      transform: translateY(-50%);
      font-weight: bold;

      .spe {
        font-size: 16px;
      }
    }

    .inf {
      color: #ed2e2e;
      font-size: 12px;
      position: absolute;
      left: 108px;
      top: 50%;
      transform: translateY(-50%);

      .spe {
        font-size: 15px;
        margin-bottom: 4px;
        text-align: left;
      }
    }

    .get-coupon {
      width: 80px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #feffed;
      font-size: 14px;
      position: absolute;
      right: 13px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 15px;
      background: linear-gradient(180deg,
      rgba(247, 91, 91, 1) 0%,
      rgba(237, 46, 46, 1) 100%);
    }
  }
}

.SpellGroupWrapStyle {
  margin-left: 20px;
}

.numberTuan {
  display: inline-block;
  text-align: left;
  padding: 0 0 0 12px;
  width: 80px;
  height: 30px;
  border-radius: 25px;
  color: #978E25;
  font-size: 14px;
  font-weight: 700;
  line-height: 30px;
  background: -webkit-linear-gradient(left, #FAFBAA, #FCFD99);
}

.immediately {
  display: inline-block;
  margin-left: -30px;
  width: 80px;
  height: 30px;
  line-height: 30px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background: -webkit-linear-gradient(left, #FA8A7F, #F95E52);
  text-align: center;
}

.isBigGiftBag {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;

  span {
    height: 19px;
    padding: 0 10px;
    margin-right: 10px;
    text-align: center;
    font-size: 12px;
    color: #D14A48;
    border-radius: 3px;
    line-height: 19px;
    background: #FFD8DC;
    display: inline-block;
  }
}

.goodsinfo_tags_box {
  text-align: left;
  margin-bottom: 10px;

  span {
    background: #1b1716;
    border-radius: 3px;
    padding: 3px 5px;
    margin: 0 10px 10px 0;
    font-size: 10px;
    color: #fff;
  }
}
</style>
